<template>
  <div />
</template>
<script>
export default {
  created() {
    const { params, query } = this.$route
    const { path } = params
    this.$router.replace({ path: '/' + path.join('/'), query })
  }
}
</script>
